<template>
  <!-- 首页轮播图 -->
  <div id="navigation-carousel">
    <el-carousel height="100%">
      <el-carousel-item v-for="item in carousels" v-bind:key="item.id">
        <a v-for="(imgItem) in item.bgUrl" v-bind:key="imgItem.id">
          <div class="carousel-img"
              v-bind:style="{'background-image': 'url(' + imgItem.url + ')','background-repeat':'no-repeat','background-size':'cover','width':'100%','height':'100%','background-position': 'center'}">
          </div>
        </a>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "zone-carousel",
  props: ["carousels"]
}
</script>

<style scoped>


img {
  height: auto;
  width: auto \9;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #navigation-carousel {
    width: 100%;
    background: #EDEDED;
    height: 100%;
  }

  .el-carousel__item img {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    /*line-height: 150px;*/
    height: 100% !important;
    margin: 0;
  }

  .el-carousel {
    height: 100%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  #navigation-carousel {
    width: 100%;
    height: 100%;
    background: #EDEDED;
  }

  #navigation-carousel .el-carousel {
    height: 100%;
  }

  .el-carousel__item img {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    /*line-height: 150px;*/
    height: 100% !important;
    margin: 0;
  }

  .el-carousel {
    height: 100%;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  #navigation-carousel {
    width: 100%;
    height: 100%;
    background: #EDEDED;
  }

  #navigation-carousel .el-carousel {
    height: 100%;
  }

  .el-carousel__item img {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    /*line-height: 150px;*/
    height: 100% !important;
    margin: 0;
  }

  .el-carousel {
    height: 100%;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
}

@media (min-width: 1200px) {
  #navigation-carousel {
    width: 100%;
    height: 100%;
    background: #EDEDED;
  }

  #navigation-carousel .el-carousel {
    height: 100%;
  }

  .el-carousel__item img {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    /*line-height: 150px;*/
    height: 100% !important;
    margin: 0;
  }

  .el-carousel {
    height: 100%;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
}

</style>
