<template>
  <div class="introduce-main">
    <zone-header :companyId="companyId" :id="id" class="home-head"></zone-header>
    <zone-carousel :carouselList="carouselList" class="main-carousel"></zone-carousel>
    <div class="main-content">
      <div class="content-info">
        <div class="content-info-head">
          <div class="content-info-image left">
            <img src="../../assets/image/pc/icon_heart.png">
          </div>
          <div class="content-info-title left">
            <span>关于我们</span>
          </div>
        </div>
        <div class="tender_con">
            <div class="news_child_1" v-html="content.contentDetails">
            </div>
        </div>
      </div>
    </div>
    <zone-footer class="left"></zone-footer>
  </div>
</template>

<script>
import ZoneFooter from "./zone-footer";
import zoneHeader from "./zone-header";
import ZoneCarousel from "./zone-carousel";

export default {
  name: "zone-introduce",
  components:{
    ZoneCarousel,
    ZoneFooter,
    zoneHeader
  },
  created() {
    this.headCompanyId = this.$route.query.companyId;
    this.headId = this.$route.query.id;
    this.headLogo = this.$route.query.headLogo;
    this.id = this.$route.query.id;
    if (this.companyId == null || this.companyId == "") {
      this.isCompany = false;
    }
    this.selectPromotionList("link", "link", "linkList");
    /*初始化轮播图*/
    this.selectPromotionList("carousel", "carousel", "carouselList");
    /*初始化导航栏*/
    this.selectCategoryList("company_type", "categoryList");
  },
  data() {
    return {
      entityBuyer: {},
      companyId: "",
      id: "",
      headCompanyId: "",
      headLogo: "",
      headId: "",
      content: {},
      carouselList: []
    };
  },
  methods: {
    /*查询推广列表*/
    selectPromotionList: function (modelId, entityName, entityListName) {
      var that = this;
      that.$http.get(that.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[entityName] = data.data.result;
        var categoryId = data.data.result.id;
        that.$http.get(that.base + "/promotion/list.do", {
          params: {
            categoryId: categoryId,
            id: that.companyId
          }
        }).then(function (data) {
          that.zhuanquSize = data.data.result.length;
          for (var i = 0; i < data.data.result.length; i++) {
            if (data.data.result[i].imagesUrl != null && data.data.result[i].imagesUrl != "") {
              data.data.result[i].imagesUrl = JSON.parse(data.data.result[i].imagesUrl);
            }
            if (data.data.result[i].categoryId == categoryId && data.data.result[i].id == that.headCompanyId) {
              that.entityBuyer = data.data.result[i];
              if (data.data.result[i].imagesUrl != null && data.data.result[i].imagesUrl != "") {
                that.entityBuyer.imagesUrl = data.data.result[i].imagesUrl[0].url;
              }
            }
          }
          that[entityListName] = data.data.result;
        }).catch(function (error) {

        })
      }).catch(function (error) {

      })
    },
    getParameter: function (paraName) {
      var url = document.location.toString();
      if (url.lastIndexOf('#') == (url.length - 1)) {
        url = url.substring(0, url.lastIndexOf('#'));
      }
      var arrObj = url.split("?");

      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;

        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");

          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    /*查询栏目列表*/
    selectCategoryList: function (modelId, name) {
      var that = this;
      that.$http.get(that.base + "/category/list.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[name] = data.data.result;
        for (let i = 0; i < data.data.result.length; i++) {
          if (data.data.result[i].categoryKeyword === 'company_detail'){
            that.selectContent(data.data.result[i].id)
          }
        }
      }).catch(function (error) {
        console.log(error)
      })
    },
    /*查询文章*/
    selectContent:function (id){
      var that = this;
      that.$http.post(that.base + "/content/page.do",{
        page:{
          current:0,
          size:10
        },
        cmsContentDTO: {
          contentCategoryId:id,
          contentRelease: that.headCompanyId
        }
      }).then(function (res) {
        that.content=res.data.result.records[0];
      }).catch(function (err) {
        that.loading = false;
      });
    },
  }
}
</script>

<style scoped>
.introduce-main{
  width: 100%;
  margin: 0 auto;
}
.home-head{
  width: 100%;
  margin: 0 auto;
}
.main-content{
  width: 1160px;
  padding: 20px 0;
  margin: 0 auto;
  margin-bottom: 10px;
}
.main-carousel{
  height: 279px !important;
  width: 1160px !important;
  margin: 0 auto;
}
.content-info{
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}
.content-info-head{
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #F2F2F2;
}
.tender_con{
  width: 100%;
  padding-top: 12px;
}
.news_child_1{
  padding: 0 20px;
}
.content-info-image{
  width: 50px;
  height: 50px;
  margin: 10px 10px;
}
.content-info-title{
  font-size: 2rem;
  font-family: MicrosoftYaHei;
  padding:10px 0 0 10px;
  font-weight: bold;
}

@media (min-width: 992px) and (max-width: 1200px){
  .introduce-main{
    width: 960px;
    margin: 0 auto;
  }
  .main-content{
    width: 100%;
    padding: 20px 0;
  }
  .content-info{
    width: 100%;
    background-color: #FFFFFF;
  }
  .content-info-head{
    height: 60px;
    width: 100%;
    border-bottom: 1px solid #F2F2F2;
  }
  .tender_con{
    width: 100%;
    padding-top: 12px;
  }
  .news_child_1{
    padding: 0 20px;
  }
  .content-info-image{
    width: 50px;
    height: 50px;
    margin: 10px 10px;
  }
  .content-info-title{
    font-size: 2rem;
    font-family: MicrosoftYaHei;
    padding:10px 0 0 10px;
    font-weight: bold;
  }
  .main-carousel{
    height: 279px !important;
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 992px){
  .introduce-main{
    width: 720px;
    margin: 0 auto;
  }
  .main-content{
    width: 100%;
    padding: 20px 0;
  }
  .content-info{
    width: 100%;
    background-color: #FFFFFF;
  }
  .content-info-head{
    height: 60px;
    width: 100%;
    border-bottom: 1px solid #F2F2F2;
  }
  .tender_con{
    width: 100%;
    padding-top: 12px;
  }
  .news_child_1{
    padding: 0 20px;
  }
  .content-info-image{
    width: 40px;
    height: 40px;
    margin: 5px 5px;
  }
  .content-info-title{
    font-size: 1.6rem;
    font-family: MicrosoftYaHei;
    padding:10px 0 0 10px;
    font-weight: bold;
  }
  .main-carousel{
    height: 249px !important;
    width: 100% !important;
  }
}
@media (max-width: 768px){
  .introduce-main{
    width: 100%;
    margin: 0 auto;
  }
  .main-content{
    width: 100%;
    padding: 20px 0;
  }
  .content-info{
    height: 100%;
    background-color: #FFFFFF;
  }

  .content-info-head{
    height: 40px;
    width: 100%;
    border-bottom: 1px solid #F2F2F2;
  }
  .tender_con{
    width: 100%;
    padding-top: 12px;
  }
  .news_child_1{
    padding: 0 20px;
  }
  .content-info-image{
    width: 30px;
    height: 30px;
    margin: 5px 5px;
  }
  .content-info-title{
    font-size: 1.6rem;
    font-family: MicrosoftYaHei;
    padding:10px 0 0 10px;
    font-weight: bold;
  }
  .main-carousel{
    height: 249px !important;
    width: 100% !important;
  }
}
</style>